import { Box, Grid, Typography } from "@mui/material";

interface PropsType {
    img: string;
    name: string;
    date: string | null;
    time: string | null;
    firstResult: string | number | null;
    threePrefix: string | number | null;
    threeBottom: string | number | null;
    twoBottom: string | number | null;
    lastGrid: number;
}

const SingleResult = ({ img, name, date, time, firstResult, threePrefix, threeBottom, twoBottom, lastGrid }: PropsType) => {
    return (
        <Box sx={{
            backgroundColor: 'white',
            width: '100%',
            borderRadius: 3,
            color: 'black',
        }}>
            <Box p={1}>
                <Box display={`flex`}>
                    <img src={img} alt={`flag_${name}`} style={{
                        border: '1px solid transparent',
                        display: 'inline',
                        width: '25px',
                        height: '25px',
                        objectFit: 'cover',
                        borderRadius: '50%',
                        backgroundColor: '#fff',
                    }} />
                    &nbsp;
                    <Typography>
                        {name}
                    </Typography>
                    &nbsp;
                    {
                        (date || time) && <Typography sx={{
                            backgroundColor: '#343a40',
                            color: 'white',
                            borderRadius: 2,
                            px: 1,
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center'
                        }} fontSize={12}>
                            {date && date} { time && time}
                        </Typography>
                    }
                </Box>
                {
                    firstResult && <>
                        <Box sx={{
                            backgroundColor: '#f7f7f7',
                            mt: .5
                        }}>
                            <Typography align="center" color={'#cf1f2d'} fontSize={22}>
                                รางวัลที่ 1
                            </Typography>
                        </Box>
                        <Typography align="center">
                            {firstResult}
                        </Typography>
                    </>
                }
                <Grid container spacing={1}>
                    {
                        threePrefix && <Grid item xs={lastGrid}>
                            <Box sx={{
                                backgroundColor: '#f7f7f7',
                                mt: .5
                            }}>
                                <Typography align="center" color={'#cf1f2d'} fontSize={22}>
                                    3 ตัวบน
                                </Typography>
                            </Box>
                            <Typography align="center">
                                {threePrefix}
                            </Typography>
                        </Grid>
                    }
                    {
                        threeBottom && <Grid item xs={lastGrid}>
                            <Box sx={{
                                backgroundColor: '#f7f7f7',
                                mt: .5
                            }}>
                                <Typography align="center" color={'#cf1f2d'} fontSize={22}>
                                    3 ตัวล่าง
                                </Typography>
                            </Box>
                            <Typography align="center">
                                {
                                    threeBottom.toString().split(",").map((element: string, index: number) => (index + 1) < threeBottom.toString().split(",").length ? `${element}, ` : element)
                                }
                            </Typography>
                        </Grid>
                    }
                    {
                        twoBottom && <Grid item xs={lastGrid}>
                            <Box sx={{
                                backgroundColor: '#f7f7f7',
                                mt: .5
                            }}>
                                <Typography align="center" color={'#cf1f2d'} fontSize={22}>
                                    2 ตัวล่าง
                                </Typography>
                            </Box>
                            <Typography align="center">
                                {twoBottom}
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )
}

export default SingleResult;