import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";

import CategoryClean from './../assets/images/button_bg/clean.png';
import CategoryCleanHover from './../assets/images/button_bg/cleanhover.png';
import DiceIcon from './../assets/images/icons/dice.png';
import SlotIcon from './../assets/images/icons/slot.png';
import ChipIcon from './../assets/images/icons/chip.png';
import CardIcon from './../assets/images/icons/cards_hires.png';
import LottoTab from "./GameTab/LottoTab";
import GameTab from "./GameTab/GameTab";
import About from "./About";
import MemberHome from "./Member/MemberHome";

const Home = () => {
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    let location = useLocation();
    const [gameTab, setGameTab] = useState<string>('lotto');
    const { game_tab } = useParams();
    const [pathName, setPathName] = useState<string | null>(null);

    useEffect(() => {
        if(game_tab && (game_tab === 'lotto' || game_tab === 'casino' || game_tab === 'slot' || game_tab === 'fish'))
        {
            setGameTab(game_tab);
        }
    }, [game_tab]);

    useEffect(() => {
        setPathName(location.pathname);
    }, [location]);

    // const [tabOffsets, setTabOffsets] = useState<number>(0);

    // const toggleVisibleTab = () => {
    //     const element = document.getElementById("tab-game") as HTMLElement;
    //     const stickyOffset = element.offsetTop;
    //     const setTop = 60;

    //     if (window.pageYOffset > (stickyOffset > setTop ? stickyOffset : tabOffsets)) {
    //         if(stickyOffset > setTop)
    //         {
    //             setTabOffsets(stickyOffset);
    //         }
    //         element.style.position = 'fixed';
    //         element.style.top = `${setTop}px`;
    //         element.style.width = '100%';
    //     } else {
    //         element.style.position = 'static';
    //     }
    // }

    // window.addEventListener('scroll', toggleVisibleTab);

    return (
        cookies.get('access_token') && (pathName && (pathName.toString() === '/' || pathName.includes("/deposit") || pathName.includes("/withdraw"))) ? <>
            <MemberHome />
            <Box mt={10}></Box>
            <About />
        </> : <>
            <Box mt={3}></Box>
            {/* BUTTON */}
                <Box id="tab-game">
                    <Grid container spacing={1} display={`flex`} justifyContent={`center`}>
                        <Grid item xs={3} md={3}>
                            <Button sx={{
                                background: `url("${CategoryClean}")`,
                                backgroundSize: 'cover',
                                color: 'black',
                                justifyContent: `center`,
                                '&:hover': {
                                    background: `url("${CategoryCleanHover}")`,
                                    backgroundSize: 'cover',
                                    color: 'white'
                                },
                                height: '100%'
                            }} fullWidth onClick={() => navigate('/game/lotto')}>
                                <img src={DiceIcon} alt="Dice" width={`100%`} style={{ maxWidth: 20 }} />
                                <Typography>
                                    หวย
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Button sx={{
                                background: `url("${CategoryClean}")`,
                                backgroundSize: 'cover',
                                color: 'black',
                                justifyContent: `center`,
                                '&:hover': {
                                    background: `url("${CategoryCleanHover}")`,
                                    backgroundSize: 'cover',
                                    color: 'white'
                                },
                                height: '100%'
                            }} fullWidth onClick={() => navigate('/game/slot')}>
                                <img src={SlotIcon} alt="Slot" width={`100%`} style={{ maxWidth: 20 }} />
                                <Typography>
                                    สล็อต
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Button sx={{
                                background: `url("${CategoryClean}")`,
                                backgroundSize: 'cover',
                                color: 'black',
                                justifyContent: `center`,
                                '&:hover': {
                                    background: `url("${CategoryCleanHover}")`,
                                    backgroundSize: 'cover',
                                    color: 'white'
                                },
                                height: '100%'
                            }} fullWidth onClick={() => navigate('/game/casino')}>
                                <img src={ChipIcon} alt="Chip" width={`100%`} style={{ maxWidth: 20 }} />
                                <Typography>
                                    คาสิโน
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <Button sx={{
                                background: `url("${CategoryClean}")`,
                                backgroundSize: 'cover',
                                color: 'black',
                                justifyContent: `center`,
                                '&:hover': {
                                    background: `url("${CategoryCleanHover}")`,
                                    backgroundSize: 'cover',
                                    color: 'white'
                                },
                                height: '100%'
                            }} fullWidth onClick={() => navigate('/game/fish')}>
                                <img src={CardIcon} alt="Card" width={`100%`} style={{ maxWidth: 20 }} />
                                <Typography>
                                    เกม
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            {/* END BUTTON */}
            {/* GAME */}
                <Grid container spacing={2} mt={1}>
                    {
                        gameTab === 'lotto' ? <LottoTab /> : <GameTab game={gameTab} />
                    }
                </Grid>
            {/* END GAME */}
            <Box mt={10}></Box>
            <About />
        </>
    )
}

export default Home;