import { Box, Button, Divider, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ApiService } from "../../services/api.service";
import { userDataDetail } from "../../types";
import InfoIcon from '@mui/icons-material/Info';
import DiceSpin from "../Loading/DiceSpin";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

const bank_list = [
    {
        "bank_abbr": "BBL",
        "bank_code": "002",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bbl.png",
        "bank_name_en": "Bangkok Bank",
        "bank_name_th": "ธนาคารกรุงเทพ"
    },
    {
        "bank_abbr": "KBANK",
        "bank_code": "004",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kbank.png",
        "bank_name_en": "Kasikorn Bank",
        "bank_name_th": "ธนาคารกสิกรไทย"
    },
    {
        "bank_abbr": "KTB",
        "bank_code": "006",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ktb.png",
        "bank_name_en": "Thai Bank",
        "bank_name_th": "ธนาคารกรุงไทย"
    },
    {
        "bank_abbr": "TTB",
        "bank_code": "011",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ttb.png",
        "bank_name_en": "TMBThanachart Bank",
        "bank_name_th": "ธนาคารทหารไทยธนชาต"
    },
    {
        "bank_abbr": "SCB",
        "bank_code": "014",
        "bank_logo": "https://cdn.bullpay.me/images/logo/scb.png",
        "bank_name_en": "The Siam Commercial Bank",
        "bank_name_th": "ธนาคารไทยพาณิชย์"
    },
    {
        "bank_abbr": "BAY",
        "bank_code": "025",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bay.png",
        "bank_name_en": "Bank of Ayudhya Bank",
        "bank_name_th": "ธนาคารกรุงศรีอยุธยา"
    },
    {
        "bank_abbr": "KKP",
        "bank_code": "069",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kkp.png",
        "bank_name_en": "KIATNAKIN PHATRA BANK",
        "bank_name_th": "ธนาคารเกียรตินาคินภัทร"
    },
    {
        "bank_abbr": "CIMBT",
        "bank_code": "022",
        "bank_logo": "https://cdn.bullpay.me/images/logo/cimb.png",
        "bank_name_en": "CIMB Thai Bank",
        "bank_name_th": "ธนาคารซีไอเอ็มบีไทย"
    },
    {
        "bank_abbr": "TISCO",
        "bank_code": "067",
        "bank_logo": "https://cdn.bullpay.me/images/logo/tisco.png",
        "bank_name_en": "TISCO Bank",
        "bank_name_th": "ธนาคารทิสโก้"
    },
    {
        "bank_abbr": "UOBT",
        "bank_code": "024",
        "bank_logo": "https://cdn.bullpay.me/images/logo/uob.png",
        "bank_name_en": "United Chinese Bank",
        "bank_name_th": "ธนาคารยูโอบี"
    },
    {
        "bank_abbr": "LHFG",
        "bank_code": "073",
        "bank_logo": "https://cdn.bullpay.me/images/logo/lhbank.png",
        "bank_name_en": "Land and Houses",
        "bank_name_th": "ธนาคารแลนด์ แอนด์ เฮ้าส์"
    },
    {
        "bank_abbr": "BAAC",
        "bank_code": "034",
        "bank_logo": "https://cdn.bullpay.me/images/logo/baac.png",
        "bank_name_en": "Bank for Agriculture and Agricultural Cooperatives",
        "bank_name_th": "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
    },
    {
        "bank_abbr": "GSB",
        "bank_code": "030",
        "bank_logo": "https://cdn.bullpay.me/images/logo/gsb.png",
        "bank_name_en": "Government Savings Bank",
        "bank_name_th": "ธนาคารออมสิน"
    },
    {
        "bank_abbr": "GHB",
        "bank_code": "033",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ghb.png",
        "bank_name_en": "Government Housing Bank",
        "bank_name_th": "ธนาคารอาคารสงเคราะห์"
    },
    // {
    //     "bank_abbr": "TMW",
    //     "bank_code": "099",
    //     "bank_logo": "https://cdn.bullpay.me/images/logo/turemoney.png",
    //     "bank_name_en": "True Money Wallet",
    //     "bank_name_th": "ทรูมันนี่วอลเล็ท"
    // }
];

interface PropsType {
    isOpenState: boolean;
    setIsOpenState: (state: boolean) => void;
}

const MemberDetailPopup = ({ isOpenState, setIsOpenState }: PropsType) => {
    const cookies = new Cookies(null, { path: '/' });
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [memberData, setMemberData] = useState<userDataDetail | null>(null);

    useEffect(() => {
        setIsOpen(isOpenState);

        if(isOpenState && cookies.get('access_token'))
        {
            getData();
        }
    }, [isOpenState]);

    const getData = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            const service = new ApiService();
            const getDetail = await service.getMemberDetailPopup(cookies.get('access_token'));

            if(getDetail.data.status_code === 200)
            {
                setMemberData(getDetail.data.detail);
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        <>
            <Box
                position={`fixed`}
                width={`100%`}
                height={`100%`}
                display={isOpen ? `block` : `none`}
                flexDirection={`column`}
                justifyContent={`center`}
                alignContent={`center`}
                alignItems={`center`}
                color={`black`}
                zIndex={40}
                gap={2}
                top={0}
                left={0}
                sx={{
                    backgroundColor: {
                        xs: 'rgba(0, 0, 0, .6)',
                        md: 'rgba(0, 0, 0, .5)'
                    },
                    overflow: 'scroll'
                }}
            >
                <Box
                    display={`flex`}
                    flexDirection={`column`}
                    justifyContent={`center`}
                    alignContent={`center`}
                    alignItems={`center`}
                    gap={2}
                    sx={{ px: { xs: 3, md: 0 } }}
                >
                    <Box display={`flex`} gap={2}>
                        <Box sx={{
                            backgroundColor: 'white',
                            borderRadius: '15px 15px 15px 15px',
                            minWidth: '350px',
                            width: 'auto',
                            maxWidth: {
                                xs: '400px !important',
                                sm: '500px !important',
                                md: '600px !important',
                                lg: '600px !important'
                            }
                        }}>
                            <Box bgcolor={'white'} sx={{ borderRadius: '15px 15px 15px 15px' }}>
                                <Box p={2}>
                                    <Typography display={`flex`} alignContent={`center`} alignItems={`center`} fontSize={18}>
                                        <InfoIcon /> ข้อมูลผู้ใช้
                                    </Typography>
                                    <Divider />
                                    {
                                        memberData ? <>
                                            <Box mt={1}></Box>
                                            <Box display={`flex`} justifyContent={`space-between`}>
                                                <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                    เลขผู้ใช้งาน:
                                                </Typography>
                                                <Typography display={`flex`} alignContent={`center`} alignItems={`center`} fontWeight={`bold`}>
                                                    {
                                                        memberData.id
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box display={`flex`} justifyContent={`space-between`}>
                                                <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                    เบอร์โทรศัพท์:
                                                </Typography>
                                                <Typography display={`flex`} alignContent={`center`} alignItems={`center`} fontWeight={`bold`}>
                                                    {
                                                        memberData.phone
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box display={`flex`} justifyContent={`space-between`}>
                                                <Typography display={`flex`} alignContent={`center`} alignItems={`center`}>
                                                    ชื่อยูสเซอร์:
                                                </Typography>
                                                <Typography display={`flex`} alignContent={`center`} alignItems={`center`} fontWeight={`bold`}>
                                                    {
                                                        memberData.username
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box mt={1}></Box>
                                            <Divider />
                                            <Box mt={1}></Box>
                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`} fontSize={18}>
                                                <AccountBalanceIcon /> ข้อมูลบัญชี
                                            </Typography>
                                            <Box mt={1}></Box>
                                            <Box display={`flex`}>
                                                <Box>
                                                    <img src={bank_list[bank_list.findIndex((element: any) => element.bank_abbr === memberData.bank)].bank_logo} alt={memberData.bank} style={{ width: `100%`, maxWidth: '48px', borderRadius: '9999px' }} />
                                                </Box>
                                                <Box mx={1}></Box>
                                                <Box>
                                                    <Typography fontWeight={`bold`} fontSize={22} sx={{ lineHeight: 1.5 }}>
                                                        {
                                                            memberData.bank_number
                                                        }
                                                    </Typography>
                                                    <Typography color={`rgb(64, 64, 64)`} fontWeight={`bold`} sx={{ lineHeight: .5 }} fontSize={16}>
                                                        {
                                                            memberData.full_name
                                                        }
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box mt={1}></Box>
                                            <Divider />
                                            <Box mt={1}></Box>
                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`} fontSize={18}>
                                                <PeopleAltIcon /> ข้อมูลแนะนำเพื่อน
                                            </Typography>
                                            <Box mt={1}></Box>
                                            <Box display={`flex`} justifyContent={`space-between`}>
                                                <Typography display={`flex`} alignContent={`center`} alignItems={`center`} color={`rgb(64, 64, 64)`}>
                                                    จำนวนเพื่อนที่เราแนะนำ:
                                                </Typography>
                                                <Typography display={`flex`} alignContent={`center`} alignItems={`center`} fontWeight={`bold`}>
                                                    {
                                                        parseFloat(memberData.friend_count.toString()).toLocaleString('en-US', {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0
                                                        })
                                                    }&nbsp;คน
                                                </Typography>
                                            </Box>
                                        </> : <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} my={5}>
                                            <DiceSpin />
                                        </Box>
                                    }
                                </Box>
                            </Box>
                            <Box pb={1} px={2}>
                                <Divider sx={{ mt: -1 }} />
                                <Button fullWidth sx={
                                    {
                                        mt: 1,
                                        backgroundColor: '#9c9c9c',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#696969',
                                            color: 'white',
                                        }
                                    }
                                } onClick={() => setIsOpenState(false)}>
                                    <Typography fontSize={16} fontWeight={500}>
                                        ปิดหน้าต่าง
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default MemberDetailPopup;