import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, CircularProgress, FormControl, FormLabel, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";

const bank_list = [
    {
        "bank_abbr": "BBL",
        "bank_code": "002",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bbl.png",
        "bank_name_en": "Bangkok Bank",
        "bank_name_th": "ธนาคารกรุงเทพ"
    },
    {
        "bank_abbr": "KBANK",
        "bank_code": "004",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kbank.png",
        "bank_name_en": "Kasikorn Bank",
        "bank_name_th": "ธนาคารกสิกรไทย"
    },
    {
        "bank_abbr": "KTB",
        "bank_code": "006",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ktb.png",
        "bank_name_en": "Thai Bank",
        "bank_name_th": "ธนาคารกรุงไทย"
    },
    {
        "bank_abbr": "TTB",
        "bank_code": "011",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ttb.png",
        "bank_name_en": "TMBThanachart Bank",
        "bank_name_th": "ธนาคารทหารไทยธนชาต"
    },
    {
        "bank_abbr": "SCB",
        "bank_code": "014",
        "bank_logo": "https://cdn.bullpay.me/images/logo/scb.png",
        "bank_name_en": "The Siam Commercial Bank",
        "bank_name_th": "ธนาคารไทยพาณิชย์"
    },
    {
        "bank_abbr": "BAY",
        "bank_code": "025",
        "bank_logo": "https://cdn.bullpay.me/images/logo/bay.png",
        "bank_name_en": "Bank of Ayudhya Bank",
        "bank_name_th": "ธนาคารกรุงศรีอยุธยา"
    },
    {
        "bank_abbr": "KKP",
        "bank_code": "069",
        "bank_logo": "https://cdn.bullpay.me/images/logo/kkp.png",
        "bank_name_en": "KIATNAKIN PHATRA BANK",
        "bank_name_th": "ธนาคารเกียรตินาคินภัทร"
    },
    {
        "bank_abbr": "CIMBT",
        "bank_code": "022",
        "bank_logo": "https://cdn.bullpay.me/images/logo/cimb.png",
        "bank_name_en": "CIMB Thai Bank",
        "bank_name_th": "ธนาคารซีไอเอ็มบีไทย"
    },
    {
        "bank_abbr": "TISCO",
        "bank_code": "067",
        "bank_logo": "https://cdn.bullpay.me/images/logo/tisco.png",
        "bank_name_en": "TISCO Bank",
        "bank_name_th": "ธนาคารทิสโก้"
    },
    {
        "bank_abbr": "UOBT",
        "bank_code": "024",
        "bank_logo": "https://cdn.bullpay.me/images/logo/uob.png",
        "bank_name_en": "United Chinese Bank",
        "bank_name_th": "ธนาคารยูโอบี"
    },
    {
        "bank_abbr": "LHFG",
        "bank_code": "073",
        "bank_logo": "https://cdn.bullpay.me/images/logo/lhbank.png",
        "bank_name_en": "Land and Houses",
        "bank_name_th": "ธนาคารแลนด์ แอนด์ เฮ้าส์"
    },
    {
        "bank_abbr": "BAAC",
        "bank_code": "034",
        "bank_logo": "https://cdn.bullpay.me/images/logo/baac.png",
        "bank_name_en": "Bank for Agriculture and Agricultural Cooperatives",
        "bank_name_th": "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
    },
    {
        "bank_abbr": "GSB",
        "bank_code": "030",
        "bank_logo": "https://cdn.bullpay.me/images/logo/gsb.png",
        "bank_name_en": "Government Savings Bank",
        "bank_name_th": "ธนาคารออมสิน"
    },
    {
        "bank_abbr": "GHB",
        "bank_code": "033",
        "bank_logo": "https://cdn.bullpay.me/images/logo/ghb.png",
        "bank_name_en": "Government Housing Bank",
        "bank_name_th": "ธนาคารอาคารสงเคราะห์"
    },
    // {
    //     "bank_abbr": "TMW",
    //     "bank_code": "099",
    //     "bank_logo": "https://cdn.bullpay.me/images/logo/turemoney.png",
    //     "bank_name_en": "True Money Wallet",
    //     "bank_name_th": "ทรูมันนี่วอลเล็ท"
    // }
];

const isNumericString = (str: string) => {
    return /^\d+$/.test(str);
}

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const validate_password = (input: string) => {
    return input.includes(' ') ? false : true;
}

const RegisterDesktop = () => {
    const query = useQuery();
    const cookies = new Cookies(null, { path: '/' });
    const by = query.get('by');
    const aff = query.get('aff');
    const [loaded, setLoaded] = useState<boolean>(false);
    const navigate = useNavigate();
    const [regBy, setRegBy] = useState<string | null>(null);
    const [affBy, setAffBy] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const usernameRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const passwordConfirmRef = useRef<HTMLInputElement>(null);
    const firstnameRef = useRef<HTMLInputElement>(null);
    const lastnameRef = useRef<HTMLInputElement>(null);
    const lineRef = useRef<HTMLInputElement>(null);
    const bankRef = useRef<HTMLSelectElement>(null);
    const bankNumberRef = useRef<HTMLInputElement>(null);
    // const knowAtRef = useRef<HTMLSelectElement>(null);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => e.preventDefault();

    useEffect(() => {
        const ref = document.referrer;
        if(!cookies.get('referrer') || cookies.get('referrer') === '')
        {
            cookies.set('referrer', ref, {
                domain: 'lotto69.com', // ไม่ใส่จุดนำหน้า
                path: '/',
            });
        }
    }, []);

    useEffect(() => {
        if(by)
        {
            cookies.set('reg_by', by, {
                domain: 'lotto69.com', // ไม่ใส่จุดนำหน้า
                path: '/',
            });
            setRegBy(by);

            addClick(by);
        }
        else
        {
            setRegBy(!cookies.get('reg_by') ? null : cookies.get('reg_by'));
            if(cookies.get('reg_by'))
            {
                addClick(cookies.get('reg_by'));
            }
        }
    }, [by]);

    useEffect(() => {
        if(aff)
        {
            cookies.set('aff', aff, {
                domain: 'lotto69.com', // ไม่ใส่จุดนำหน้า
                path: '/',
            });
            setAffBy(aff);
        }
        else
        {
            setAffBy(!cookies.get('aff') ? null : cookies.get('aff'));
        }
    }, [aff]);

    const addClick = async (reg_by: string) => {
        try {
            const service = new ApiService();
            await service.addClickCount(reg_by);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if(!cookies.get('access_token'))
                {
                    setLoaded(true);
                    return;
                }

                const access_token: string = cookies.get('access_token');
                const service = new ApiService();
                const checkToken = (await service.check_access_token(access_token)).data;

                if(checkToken.status_code !== 200)
                {
                    setLoaded(true);
                    return cookies.remove('access_token');
                }
                else if(checkToken.status_code === 200)
                {
                    return navigate('/');
                }
            } catch (err) {
                console.log(err);
            }
        })();
    }, []);

    const send_register = async () => {
        if(!usernameRef.current || !passwordRef.current || !passwordConfirmRef.current || !firstnameRef.current || !lastnameRef.current || !lineRef.current || !bankRef.current || !bankNumberRef.current)
        {
            console.log(lineRef.current)
            return;
        }

        const phone = usernameRef.current.value.trim();
        const password = passwordRef.current.value.trim();
        const passwordConfirm = passwordConfirmRef.current.value.trim();
        const first_name = firstnameRef.current.value.trim();
        const last_name = lastnameRef.current.value.trim();
        const line = lineRef.current.value.trim();
        const know_at = '';
        const bank = bankRef.current.value.trim();
        const bank_number = bankNumberRef.current.value.trim();

        if(phone === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เบอร์โทรศัพท์ไม่สามารถเว้นว่างได้'
            });
        }
        else if(password === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'รหัสผ่านไม่สามารถเว้นว่างได้'
            });
        }
        else if(password !== passwordConfirm)
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'รหัสผ่านไม่ตรงกัน กรุณากรอกใหม่อีกครั้ง'
            });
        }
        else if(first_name === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'ชื่อจริงไม่สามารถเว้นว่างได้'
            });
        }
        else if(last_name === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'นามสกุลไม่สามารถเว้นว่างได้'
            });
        }
        else if(bank_number === '')
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เลขบัญชีธนาคารไม่สามารถเว้นว่างได้'
            });
        }
        else if(!validate_password(password))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'รหัสผ่านห้ามมีเว้นวรรค'
            });
        }
        // else if(!checkStringPassword(password))
        // {
        //     return Swal.fire({
        //         icon: 'error',
        //         title: 'แจ้งเตือน',
        //         text: 'รหัสผ่านจำเป็นต้องมีตัวอักษรตัวเล็ก ตัวใหญ่ และ ตัวเลข'
        //     });
        // }
        else if(password.length < 8 || password.length > 15)
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'รหัสผ่านจำเป็นต้องมี 8-15 ตัวอักษรเท่านั้น'
            });
        }
        else if(!(isNumericString(bank_number)))
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: 'เลขบัญชีจำเป็นต้องกรอกเฉพาะตัวเลขเท่านั้นค่ะ (ไม่ต้องมี - หรือ เว้นวรรค)'
            });
        }
        
        try {
            setLoading(true);
            const service = new ApiService();
            const register = await service.register({
                password: password,
                phone: phone,
                first_name: first_name,
                last_name: last_name,
                line: line,
                know_at: know_at,
                bank: bank,
                bank_number: bank_number,
                by: regBy,
                aff: affBy
            });

            setLoading(false);
            if(register.data.status_code === 200 && register.data.message === 'Success')
            {
                cookies.set('access_token', register.data.data.token, {
                    domain: 'lotto69.com', // ไม่ใส่จุดนำหน้า
                    path: '/',
                });
                cookies.set('user', register.data.data.ufa_username, {
                    domain: 'lotto69.com', // ไม่ใส่จุดนำหน้า
                    path: '/',
                });
                return Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    // text: `Username: ${phone}<br>Password: ${password}`,
                    html: `สมัครสมาชิกเรียบร้อยแล้วค่ะ<br/>Username: <b>${phone}</b><br/>Password: <b>${password}</b>`
                }).then(() => {
                    window.location.reload();
                });
            }
            else
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: register.data.message || "เกิดข้อผิดพลาดไม่ทราบสาเหตุ กรุณาลองใหม่อีกครั้ง"
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <form name="user_register" id="user_register" onSubmit={send_register}>
            <Box p={2}>
                <Typography align="center" fontSize={20} color={'#313131'}>
                    กรอกข้อมูลเพื่อสมัครสมาชิก
                </Typography>
                <Typography align="center" color={'#9c9c9c'}>
                    กรุณาใช้ข้อมูลที่ถูกต้องและเป็นจริงในการสมัครเท่านั้น
                </Typography>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <Select
                        fullWidth
                        inputRef={bankRef}
                        sx={
                            {
                                backgroundColor: 'white',
                            }
                        }
                        defaultValue={`SCB`}
                    >
                        {
                            bank_list.map((element, index: number) => {
                                return (
                                    <MenuItem value={element.bank_abbr} key={`register-bank-${index}`}>
                                        <Box display={`flex`} justifyContent={`flex-start`} alignContent={`center`} alignItems={`center`}>
                                            <img src={element.bank_logo} alt={`bank_logo_${element.bank_abbr}`} style={{ maxWidth: 20 }} />
                                            &nbsp;
                                            {element.bank_name_th}
                                        </Box>
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <Typography color={`#9c9c9c`} fontSize={13} mb={1}>
                    ธนาคารที่ง่ายต่อการตรวจสอบ: กสิกรไทย (KBANK), ไทยพาณิชย์ (SCB)
                </Typography>
                <FormControl fullWidth>
                    <OutlinedInput
                        name='user_bank_account'
                        type={`tel`}
                        fullWidth
                        autoComplete="user_bank_account"
                        placeholder="เลขที่บัญชี"
                        inputRef={bankNumberRef}
                        sx={
                            {
                                backgroundColor: 'white'
                            }
                        }
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <OutlinedInput
                        name='user_firstname'
                        type={`text`}
                        fullWidth
                        autoComplete="user_firstname"
                        placeholder="ชื่อจริง"
                        inputRef={firstnameRef}
                        sx={
                            {
                                backgroundColor: 'white'
                            }
                        }
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <OutlinedInput
                        name='user_lastname'
                        type={`text`}
                        fullWidth
                        autoComplete="user_lastname"
                        placeholder="นามสกุล"
                        inputRef={lastnameRef}
                        sx={
                            {
                                backgroundColor: 'white'
                            }
                        }
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <OutlinedInput
                        name='user_phone'
                        type={`tel`}
                        fullWidth
                        autoComplete="user_phone"
                        placeholder="หมายเลขเบอร์โทรศัพท์ 10 หลักสำหรับเข้าสู่ระบบ"
                        inputRef={usernameRef}
                        sx={
                            {
                                backgroundColor: 'white'
                            }
                        }
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <OutlinedInput
                        name='user_password'
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {
                                        showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />
                                    }
                                </IconButton>
                            </InputAdornment>
                        }
                        // size='small'
                        fullWidth
                        autoComplete="user_password"
                        placeholder="รหัสผ่าน"
                        inputRef={passwordRef}
                        sx={
                            {
                                backgroundColor: 'white'
                            }
                        }
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <OutlinedInput
                        name='user_confirm_password'
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {
                                        showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />
                                    }
                                </IconButton>
                            </InputAdornment>
                        }
                        fullWidth
                        autoComplete="user_confirm_password"
                        placeholder="ยืนยันรหัสผ่าน"
                        inputRef={passwordConfirmRef}
                        sx={
                            {
                                backgroundColor: 'white'
                            }
                        }
                    />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1, display: 'none' }}>
                    <OutlinedInput
                        name='user_line'
                        type={`text`}
                        // size='small'
                        fullWidth
                        autoComplete="user_line"
                        placeholder="ไลน์ไอดี"
                        inputRef={lineRef}
                        sx={
                            {
                                backgroundColor: 'white'
                            }
                        }
                    />
                </FormControl>
                <Button fullWidth sx={
                    {
                        mt: 1,
                        py: 1,
                        backgroundColor: '#ec2230',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#c61d29',
                            color: 'white',
                        }
                    }
                } onClick={send_register} disabled={loading}>
                    <Typography fontSize={16} fontWeight={500}>
                        {
                            loading ? <CircularProgress size={20} sx={
                                {
                                    color: 'white'
                                }
                            } /> : "สมัครสมาชิก"
                        }
                    </Typography>
                </Button>
            </Box>
        </form>
    )
}

export default RegisterDesktop;