import Cookies from "universal-cookie";
import { Box, Button, Typography } from "@mui/material";
import Logo from './../../assets/images/logo.png';
import { useState } from "react";
import { FaLine } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from "react-router-dom";
import './../../assets/css/hamburger.css';
import { useNavigate } from "react-router-dom";
import SubLoginRegister from "./SubNavMenuHeader/SubLoginRegister";
import SubDetail from "./SubNavMenuHeader/SubDetail";
import HeadersReultsIcon from './../../assets/images/icons/header_results.png';
import HeadersPromotionsIcon from './../../assets/images/icons/headers_promotions.png';
import HeadersDiceIcon from './../../assets/images/icons/header_dice.png';
import HeadersKingIcon from './../../assets/images/icons/header_king.png';
import AffiliateIcon from './../../assets/images/icons/affiliate.png';

interface PropsType {
    isOpenState: boolean;
    setIsOpenState: (state: boolean) => void;
    isOpenLoginRegisterState: boolean;
    setIsOpenLoginRegisterState: (state: boolean) => void;
    loginOrRegister: number;
    setLoginOrRegister: (state: number) => void;
    setIsOpenDepositWithdrawMenu: (state: boolean) => void;
    isOpenDepositWithdrawMenu: boolean;
    depositOrWithdraw: number;
    setDepositOrWithdraw: (state: number) => void;
    setIsOpenAffiliateMenu: (state: boolean) => void;
}

const NavMenuDesktop = ({
    isOpenState,
    setIsOpenState,
    isOpenLoginRegisterState,
    setIsOpenLoginRegisterState,
    loginOrRegister,
    setLoginOrRegister,
    isOpenDepositWithdrawMenu,
    setIsOpenDepositWithdrawMenu,
    depositOrWithdraw,
    setDepositOrWithdraw,
    setIsOpenAffiliateMenu
}: PropsType) => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, { path: '/' });
    const [fixed, setFixed] = useState<boolean>(false);
    const toggleVisible = () => { 
        const scrolled = document.documentElement.scrollTop;

        if (scrolled > 20){ 
            setFixed(true) 
        }  
        else if (scrolled <= 20){ 
            setFixed(false) 
        }
    }; 
    window.addEventListener('scroll', toggleVisible);

    const openLoginRegisterMenu = (loginorregister: number) => {
        setLoginOrRegister(loginorregister);
        setIsOpenLoginRegisterState(true);
    }

    const openDepositWithdrawMenu = (depositorwithdraw: number) => {
        setDepositOrWithdraw(1);
        setIsOpenDepositWithdrawMenu(true);
    }

    return (
        <Box width={`100%`} position={fixed ? `fixed` : `sticky`} className="header-bottom" sx={
            {
                backgroundColor: '#cf1f2d',
                py: 1.5,
                transition: 'all .10s',
                zIndex: 25,
                top: 0
            }
        }>
            <Box px={3} display={`flex`} justifyContent={`space-between`}>
                <Box display={`flex`} alignContent={`center`} alignItems={`center`}>
                    <button className="x-hamburger" style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => setIsOpenState(true)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <Link to={'/'} style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                        <img src={Logo} alt="Logo" width={`110px`} style={{ maxWidth: '110px' }} />
                    </Link>
                    <Box display={`flex`} alignContent={`center`} alignItems={`center`} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, cursor: 'pointer', '&:hover img': { rotate: '-20deg' } }} onClick={() => {
                        navigate('/')
                    }}>
                        <img src={HeadersKingIcon} alt="lotto_results" style={{ maxWidth: '20px', transition: 'all .2s' }} />
                        &nbsp;
                        <Typography>
                            หน้าแรก
                        </Typography>
                    </Box>
                    <Box sx={{ mx: 1 }}></Box>
                    <Box display={`flex`} alignContent={`center`} alignItems={`center`} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, cursor: 'pointer', '&:hover img': { rotate: '-20deg' } }} onClick={() => {
                        navigate('/promotions')
                    }}>
                        <img src={HeadersPromotionsIcon} alt="promotions" style={{ maxWidth: '20px', transition: 'all .2s' }} />
                        &nbsp;
                        <Typography>
                            โปรโมชั่น
                        </Typography>
                    </Box>
                    <Box sx={{ mx: 1 }}></Box>
                    <Box display={`flex`} alignContent={`center`} alignItems={`center`} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, cursor: 'pointer', '&:hover img': { rotate: '-20deg' } }} onClick={() => {
                        navigate('/results')
                    }}>
                        <img src={HeadersDiceIcon} alt="results" style={{ maxWidth: '20px', transition: 'all .2s' }} />
                        &nbsp;
                        <Typography>
                            ดูผลหวย
                        </Typography>
                    </Box>
                    <Box sx={{ mx: 1 }}></Box>
                    <Box display={`flex`} alignContent={`center`} alignItems={`center`} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, cursor: 'pointer', '&:hover img': { rotate: '-20deg' } }} onClick={() => {
                        setIsOpenAffiliateMenu(true);
                    }}>
                        <img src={AffiliateIcon} alt="affiliate" style={{ maxWidth: '20px', transition: 'all .2s' }} />
                        &nbsp;
                        <Typography>
                            แนะนำเพื่อน
                        </Typography>
                    </Box>
                </Box>
                <Box display={`flex`} alignContent={`center`} alignItems={`center`}>
                    {/* <Button sx={
                        {
                            mx: .5,
                            borderRadius: 2,
                            px: 2,
                            background: 'linear-gradient(to bottom, #7bcff7 1%, #47b4e5 99%)',
                            color: 'white',
                            transition: 'all .2s',
                            '&:hover': {
                                scale: '105%'
                            },
                            textTransform: 'none',
                            height: '100%',
                            maxHeight: 38
                        }
                    }>
                        <Typography fontSize={14} alignContent={`center`} alignItems={`center`} sx={{ display: { xs: 'flex', sm: 'none' } }}>
                            <FaTelegramPlane />
                        </Typography>
                        <Typography fontSize={14} alignContent={`center`} alignItems={`center`} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            <FaTelegramPlane />&nbsp;Support
                        </Typography>
                    </Button>
                    <Button sx={
                        {
                            mx: .5,
                            borderRadius: 2,
                            px: 2,
                            background: 'linear-gradient(to bottom, #a5e8a5 1%, #11c212 99%)',
                            color: 'white',
                            transition: 'all .2s',
                            '&:hover': {
                                scale: '105%'
                            },
                            textTransform: 'none',
                            height: '100%',
                            maxHeight: 38
                        }
                    }>
                        <Typography fontSize={14} alignContent={`center`} alignItems={`center`} sx={{ display: { xs: 'flex', sm: 'none' } }}>
                            <FaLine />
                        </Typography>
                        <Typography fontSize={14} alignContent={`center`} alignItems={`center`} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            <FaLine />&nbsp;Support
                        </Typography>
                    </Button> */}
                    {
                        !cookies.get('access_token') ? <SubLoginRegister openLoginRegisterMenu={openLoginRegisterMenu} /> : <SubDetail openDepositWithdrawMenu={openDepositWithdrawMenu} />
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default NavMenuDesktop;